<template>
  <div>
    <h1>Pick your flavors</h1>
    <ul class="row overflow-section">
      <li
        v-for="flavor in flavors"
        :key="flavor.flavorsId"
        class="col-2"
      >
        <div class="bg-holder">
          <input
            type="checkbox"
            :id="flavor.id"
            :value="flavor.name"
            v-model="picked"
          >
          <label :for="flavor.id" class="bg-item">
            <img
              :src="flavor.img"
              :alt="flavor.title"
              width="61"
            >
            <b>{{ flavor.name }}</b>
          </label>
        </div>
      </li>
    </ul>
    <div class="controls d-flex">
      <button
        class="btn btn-outline"
        @click="handleAddProduct($options.links.home)"
      >
        Add another item
      </button>
      <button
        @click="handleAddProduct($options.links.cart)"
        class="btn"
        :disabled="!picked.length"
      >All done?</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
    cart: ROUTES.CART,
  },
  name: 'Builderpint',
  data() {
    return {
      picked: [],
    };
  },
  // mounted() {
  //   const pintItems = this.$store.state.db.cart.filter((cartItem) => cartItem.typeOfBuild === 'pint');
  //   pintItems.map((el) => this.picked.push(...el.flavors));
  // },
  methods: {
    handleAddProduct(nextRoute) {
      const pickedItems = this.picked.map((item, index) => ({
        id: `${Date.now()}-${index}`,
        variantId: this.pintProduct.id,
        typeOfBuild: 'pint',
        title: 'Pint',
        flavors: [item],
        img: 'img08.jpg',
        price: this.pintProduct ? this.pintProduct.amount / 100 : 0,
      }));
      this.$store.commit('addToCart', pickedItems);
      this.$router.push(nextRoute);
    },
  },
  computed: {
    ...mapGetters(['flavors', 'pintProduct']),
  },
};
</script>

<style scoped>

</style>
