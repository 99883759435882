var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("How many scoops?")]),_c('ul',{staticClass:"row"},_vm._l((_vm.quantityScoop),function(quantity){return _c('li',{key:quantity.id,staticClass:"col"},[_c('Router-link',{staticClass:"holder-item",attrs:{"to":{name: 'Builder'}},nativeOn:{"click":function($event){return _vm.addScoopBuilder({
          type: 'scoop',
          catalogId: quantity.catalogId,
          variantId: quantity.id,
          quantity: quantity.quantityCount,
          price: quantity.amount / 100,
          img: quantity.previewImg,
          title: quantity.name
        })}}},[_c('img',{attrs:{"src":require(("@/assets/images/" + (quantity.img))),"alt":"image"}}),_c('div',{staticClass:"inner"},[_c('strong',{staticClass:"lg"},[_vm._v(_vm._s(quantity.name))]),_c('span',[_vm._v("$"+_vm._s(quantity.amount / 100))])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }