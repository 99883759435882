var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("What size?")]),_c('ul',{staticClass:"row"},_vm._l((_vm.sizeMilshake),function(size){return _c('li',{key:size.id,staticClass:"col"},[_c('Router-link',{staticClass:"holder-item",attrs:{"to":{name: 'Builder'}},nativeOn:{"click":function($event){return _vm.addMilkshakeBuilder({
          type: 'milkshake',
          catalogId: size.catalogId,
          variantId: size.id,
          size: size.name,
          price: size.amount / 100,
          img: size.previewImg,
        })}}},[_c('img',{attrs:{"src":require(("@/assets/images/" + (size.img))),"alt":"image"}}),_c('div',{staticClass:"inner"},[_c('b',[_vm._v(_vm._s(size.name))])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }