<template>
  <div>
    <h1>What size?</h1>
    <ul class="row">
      <li
        v-for="size in sizeMilshake"
        :key="size.id"
        class="col"
      >
        <Router-link
          :to="{name: 'Builder'}"
          @click.native="addMilkshakeBuilder({
            type: 'milkshake',
            catalogId: size.catalogId,
            variantId: size.id,
            size: size.name,
            price: size.amount / 100,
            img: size.previewImg,
          })"
          class="holder-item"
        >
          <img
            :src="require(`@/assets/images/${size.img}`)"
            alt="image"
          >
          <div class="inner">
            <b>{{ size.name }}</b>
          </div>
        </Router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MilshakeQuantity',
  computed: {
    ...mapGetters(['sizeMilshake']),
  },
  methods: {
    addMilkshakeBuilder(data) {
      this.$store.commit('clearBuild');
      this.$store.commit('addToBuilder', {
        id: Date.now(),
        catalogId: data.catalogId,
        variantId: data.variantId,
        typeOfBuild: 'milkshake',
        img: data.img,
        title: `${data.size} Milkshake`,
        flavors: [],
        milk: [],
        price: data.price,
        size: data.size,
      });
    },
  },
};
</script>

<style scoped>

</style>
