<template>
  <div class="page-content">
    <div class="container">
      <template v-if="isScoop">
        <ScoopQuantity />
      </template>
      <template v-if="isPint">
        <Builderpint />
      </template>
      <template v-if="isMilkshake">
        <MilshakeQuantity />
      </template>
      <template v-if="isSoda">
        <Buildergeneric />
      </template>
      <template v-if="isAffogato">
        <Buildergeneric />
      </template>
      <template v-if="isIceCreamSandwich">
        <Buildergeneric />
      </template>
    </div>
  </div>
</template>

<script>
import Builderpint from '@/components/Builderpint';
import ScoopQuantity from '@/components/ScoopQuantity';
import MilshakeQuantity from '@/components/MilshakeQuantity';
import Buildergeneric from '../components/Buildergeneric.vue';

export default {
  name: 'ProductOptions',
  components: {
    Builderpint,
    ScoopQuantity,
    MilshakeQuantity,
    Buildergeneric,
  },
  computed: {
    isScoop() {
      return this.$route.params.name === 'scoop';
    },
    isPint() {
      return this.$route.params.name === 'pint';
    },
    isMilkshake() {
      return this.$route.params.name === 'milkshake';
    },
    isSoda() {
      return this.$route.params.name === 'soda';
    },
    isAffogato() {
      return this.$route.params.name === 'affogato';
    },
    isIceCreamSandwich() {
      return this.$route.params.name === 'ice-cream-sandwich';
    },
  },
  mounted() {
    // Clear the current build as we are selecting a new product.
    // This was causing issues with overriding the previous build that was already in the cart.
    if (!this.isSoda && !this.isAffogato && !this.isIceCreamSandwich) {
      this.$store.commit('clearBuild');
    }
  },
};
</script>

<style scoped>

</style>
