<template>
  <div>
    <Dialog
      type="error"
      :isShow="showDialog"
      @close-dialog="showDialog = false"
    >
      Oops! Please make your selections.
    </Dialog>
    <h1>Build your {{ this.getTitle }}</h1>
    <div class="overflow-section">
      <div
        class="table-row mb-1"
        v-for="build in this.getBuild"
        :key="build.id"
      >
        <div class="table-cell-img">
          <img
            :src="require(`@/assets/images/${build.img}`)"
            :alt="build.title"
            width="80"
          >
        </div>
        <div class="table-cell-text">
          <div class="title-cell mb-1">
            <strong>{{ build.title }}</strong>
            <span @click="handleRemove(build.id)" class="close"></span>
          </div>
          <div class="cell-options d-flex">
            <Select
              type="flavors"
              :id="build.id"
              :options="flavors"
              :multiple="false"
            />
            <Select
              type="soda"
              :id="build.id"
              :options="getSoda"
              v-if="getBuildType === 'soda'"
              :multiple="false"
            />
            <Select
              type="cookie"
              :id="build.id"
              :options="productIceCreamSandwich"
              v-if="getBuildType === 'sandwich'"
              :multiple="false"
            />
          </div>
        </div>
      </div>
      <div class="control-actions d-flex mt-1">
        <div class="holder">
          <button @click="handleAddProduct" class="btn btn-outline">{{this.getButtonTitle }}</button>
          <button @click="addCart($options.links.home)" class="btn btn-outline">Add another item</button>
        </div>
      </div>
    </div>
    <div class="controls">
      <button
        class="btn"
        @click="addCart($options.links.cart)"
      >
        All done?
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
    cart: ROUTES.CART,
  },
  name: 'Buildergeneric',
  computed: {
    ...mapGetters(['flavors', 'getSoda', 'productIceCreamSandwich',
      'buildsSoda', 'buildsAffogato', 'buildsSandwich', 'clearBuild', 'build']),
    getBuild() {
      switch (this.build[0].typeOfBuild) {
        case 'soda':
          return this.buildsSoda;
        case 'affogato':
          return this.buildsAffogato;
        case 'sandwich':
          return this.buildsSandwich;
        default:
          return [];
      }
    },
    getPrice() {
      return this.getBuild.length > 0 ? this.getBuild[0].price : 0;
    },
    getCatalogId() {
      return this.getBuild.length > 0 ? this.getBuild[0].catalogId : 0;
    },
    getVariantId() {
      return this.getBuild.length > 0 ? this.getBuild[0].variantId : 0;
    },
    getTitle() {
      return this.getBuild.length > 0 ? this.getBuild[0].title : 'Item';
    },
    getImage() {
      return this.getBuild.length > 0 ? this.getBuild[0].img : '';
    },
    getBuildType() {
      return this.getBuild.length > 0 ? this.getBuild[0].typeOfBuild : '';
    },
    getButtonTitle() {
      switch (this.getBuildType) {
        case 'soda':
          return 'Add a float';
        case 'sandwich':
          return 'Add a sandwich';
        default:
          return `Add ${this.getTitle}`;
      }
    },
  },
  data() {
    return {
      count: 0,
      showDialog: false,
    };
  },
  methods: {
    handleAddProduct() {
      const props = {
        id: Date.now(),
        catalogId: this.getCatalogId,
        variantId: this.getVariantId,
        img: this.getImage,
        price: this.getPrice,
        typeOfBuild: this.getBuildType,
        title: this.getTitle,
        flavors: [],
      };

      if (this.getBuildType === 'soda') {
        props.soda = [];
      } else if (this.getBuildType === 'sandwich') {
        props.cookie = '';
      }

      this.$store.commit('addToBuilder', props);
    },
    addCart(nextRoute) {
      this.$store.dispatch('validateBuild')
        .then((isValid) => {
          if (isValid) {
            this.$store.commit('addToCart', this.build);
            this.$router.push(nextRoute);
          } else {
            this.showDialog = true;
          }
        });
    },
    handleRemove(id) {
      this.$store.commit('remove', {
        id,
        el: ['builds'],
      });
    },
  },
};
</script>

<style scoped>

</style>
