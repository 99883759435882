<template>
  <div>
    <h1>How many scoops?</h1>
    <ul class="row">
      <li
        v-for="quantity in quantityScoop"
        :key="quantity.id"
        class="col"
      >
        <Router-link
          :to="{name: 'Builder'}"
          @click.native="addScoopBuilder({
            type: 'scoop',
            catalogId: quantity.catalogId,
            variantId: quantity.id,
            quantity: quantity.quantityCount,
            price: quantity.amount / 100,
            img: quantity.previewImg,
            title: quantity.name
          })"
          class="holder-item"
        >
          <img
            :src="require(`@/assets/images/${quantity.img}`)"
            alt="image"
          >
          <div class="inner">
            <strong class="lg">{{ quantity.name }}</strong>
            <span>${{ quantity.amount / 100 }}</span>
          </div>
        </Router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ScoopQuantity',
  computed: {
    ...mapGetters(['quantityScoop']),
  },
  methods: {
    addScoopBuilder(data) {
      this.$store.commit('clearBuild');
      this.$store.commit('addToBuilder', {
        id: Date.now(),
        catalogId: data.catalogId,
        variantId: data.variantId,
        typeOfBuild: 'scoop',
        quantity: data.quantity,
        img: data.img,
        title: data.title,
        flavors: [],
        toppings: [],
        price: data.price,
      });
    },
  },
};
</script>

<style scoped>

</style>
